
.container{
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  max-width: 500px;
  margin-bottom: 20px;
}

.title{
  margin-bottom: 10px;
  font-family: var(--font-family-secondary);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.1px;
  color: #ff9e4f;
}

.content{
  border-radius: 12px;
  box-shadow: 0 4px 8px -2px #e2cbbd;
  min-height: 150px;
}
