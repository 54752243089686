.columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
  width: 100%;
}
.column {
  flex-basis: 0;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  position: relative;
  padding: 15px;
  box-sizing: border-box;
  text-align: center;
  width : 50px;
}

.wrapper {
  position: relative;
}
.title {
  text-transform: uppercase;
  font-size: 16px;

  color: #3e3330;
}
.active .title {
  font-weight: 900;
}

.column .icon {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.4;
  width: 34px;
}

.column .badge{
  width: 30px;
  position: absolute;
  bottom: 19px;
  left: calc(50% + 22px);
}

.inactive path {
  visibility: hidden;
}

.active path {
  visibility: visible;
}
.active .icon {
  opacity: 1;
}

@media (max-width: 544px) {
  .column {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
