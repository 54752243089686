.container{
  width: 100%;
  padding-top: 10px;
  display: flex;
  gap: 0.5rem
}

.divider{
  align-self:stretch;
  width: 1px;
  border-left: solid 1px #c3bbb559;
}
