.tabPage{
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.container{

}

.pageTitle {
  font-family: var(--font-family-secondary);
  font-size: 31px;
  font-weight: bold;
  margin-left: 10px;
  color: #ff9e4f;
}
