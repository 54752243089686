@mixin desktop {
  @media (min-width: 544px) {
    @content;
  }
}

.overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(62, 51, 48, 0.73);
}

.panel {
  padding-top : var(--modal-top-margin);
  background: #fbeedd;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  overflow: auto;
  z-index: 4;
  text-align: center;
  overflow-y: auto;

  @include desktop {
    width: 434px;
    height: auto;
    margin: 138px auto 0;
    border-radius: 18px;
    box-shadow: 0 2px 10px 4px rgba(42, 42, 42, 0.5);
  }
}

.panelHeader {
  border-bottom: solid 1px #c3bbb5;
  position: relative;
  height: 40px;

  h3 {
    font-family: var(--font-family-secondary);
    font-size: 20px;
    margin: 18px;
  }

  button[type='submit'] {
    position: absolute;
    right: 15px;
    top: -4px;
    padding: 8px 15px;
    font-size: 14px;
    border-radius: 3px;

    @include desktop {
      display: none;
    }
  }
}

.panelBody {
  padding: 12px 15px 0;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-primary);

  @include desktop {
    padding: 15px 25px 32px;
  }

  p {
    text-align: left;
    font-size: 22px;
    margin: 0 0 10px;

    @include desktop {
      font-size: 17px;
    }
  }

  textarea {
    margin-bottom: 15px;
    border: none;
    padding: 18px;
    font-family: var(--font-family-primary);
    font-size: 22px;
    line-height: 1.23;
    height: 170px;

    @include desktop {
      font-size: 17px;
      line-height: 1.29;
    }
  }
}

.button {
  text-transform: uppercase;
  border-radius: 5px;
  background-color: #3e3330;
  font-size: 17px;
  color: #fdf9f3;
  font-family: var(--font-family-primary);
  padding: 15px;
  border: none;

  &:disabled {
    opacity: 0.5;
  }

  @include desktop {
    padding: 10px;
  }
}

.closeButton {
  position: absolute;
  left: 15px;
  top: 5px;
  width: 20px;
  height: 20px;
  background: none;
  border: none;
  cursor: pointer;

  @include desktop {
    left: 25px;
  }

  &:before, &:after {
    position: absolute;
    left: 9px;
    top: 0px;
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: #333;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}
