
.container{
  height: 100vh;
  width: 100vw;
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textContainer{
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.25px;
  text-align: center;
  color: #3e3330;
  margin: 20px 20px 80px 20px;
  width: calc(100% - 50px);
}

.title{
  font-family: var(--font-family-secondary);
  margin-bottom: 20px;
  font-size: 50px;
}

.info{
  margin: 20px;
  font-family: var(--font-family-primary);
  font-size: 25px;
}

.buttonContainer{
  display: flex;
  height: 90px;
  justify-content: center;
  align-items: center;
}

.buttonContainer a {
  text-decoration: none;
}

.connectButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 335px;
  height: 75px;
  background-color: #fefdfc;
  font-family: var(--font-family-primary);
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.78;
  letter-spacing: normal;
  text-align: center;
  color: #fdf9f3;
  border-radius: 50px;
  box-shadow: 0 8px 10px -3px rgba(149, 137, 115, 0.5);
}

.platformIcon{
  margin-right: 15px;
}

.platformName{
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02px;
  text-align: center;
  color: #3e3330;
  font-family: var(--font-family-primary);
}

.issues{
  color: #3e3330;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  width: calc(100% - 50px);
}

.issues a{
  color: #3e3330;
}
