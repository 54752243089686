
.container{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top:25px;
  margin-bottom: 25px;
}

.inviteImg {
  width: 68px;
  height: 68px;
  margin-bottom: 10px;
}

.title{
  font-family: var(--font-family-secondary);
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #fdf9f3;
  margin-bottom: 13px;
}

.button{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: calc(100% - 40px);
  height: 65px;
  margin: 15px 0 0;
  border-radius: 32.5px;
  box-shadow: 0 2px 9px -1px #cc4c8a;
  background-color: #ffa2cf;

}

.button .text{
  font-family: var(--font-family-secondary);
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fdf9f3;
}
