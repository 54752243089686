.mobileWebContainer {
  background-color: #fdf9f3;
  font-family: var(--font-family-primary);
  color: #544b46;
  height: 100%;
  min-height: 100vh;
}


.loadingContainer {
  display: flex;
  min-height: 70vh;
  height: 70%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  font-family: var(--font-family-primary);
  color: #544b46;
}
