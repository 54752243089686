.content {
  min-height: 82px;
  position: relative;
}

.content .main {
  width: 100%;
  display: flex;
}

.content .main.updating {
  display: none;
}

.errorWrapper {
  font-size: 22px;
  line-height: 25px;
  width: 100%;
  padding-top: 5px;
}

.error {
  color: #ff744f;
}

.emoji {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.retry {
  cursor: pointer;
  color: #3e3330;
  font-style: italic;
  text-decoration: underline;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
