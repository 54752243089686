.monthSelector {
  margin-top: 10px;
  padding: 10px 0 16px 0;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  justify-content: center;
  border-bottom: 1px solid #c3bbb5;
  width: 100%;
}
.month {
  font-family: var(--font-family-secondary);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #3e3330;
  text-transform: uppercase;
}
.chevron {
  width: 16px;
  height: 16px;
  object-fit: contain;
  transform: rotate(90deg);
}
.chevronRight {
  transform: rotate(-180deg);
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  grid-column-gap: 10px;
  grid-row-gap: 25px;
  margin-top: 20px;
}
.day {
  border-radius: 20px;
  width: 22px;
  height: 22px;
  text-align: center;
  font-size: 13px;
  line-height: 22px;
  background-color: #f0eae2;
  color: #beb1a1;
  position: relative;
}
.day.active {
  background-color: #aa8dfc;
  color: transparent;
}
.stats {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
}
.stats .block {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.stats .number {
  font-size: 37px;
  float: left;
  line-height: 36px;
}
.stats .label {
  font-size: 18px;
  line-height: 18px;
  width: 80px;
}
.stats .separator {
  margin: 0 8px;
  width: 0;
  border-left: 1px solid #c3bbb5;
  height: 40px;
  display: inline-block;
}
.stats .star {
  height: 28px;
}
.current:before {
  content: "";
  position: absolute;
  top: -4px;
  right: -4px;
  left: -4px;
  bottom: -4px;
  border: 2px solid #3e3330;
  display: block;
  border-radius: 30px;
}
