.subTitle {
  border-bottom: solid 1px #c3bbb5;
  height: 48px;
  font-size: 15px;
  font-family: var(--font-family-primary);
  color: #3e3330;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aggregate {
  text-align: center;
  border-top: solid 1px #c3bbb5;
  font-size: 13px;
  font-weight: 500;
  font-family: var(--font-family-primary);
  color: #3e3330;
  padding-top: 12px;
}
.graph {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 248px;
  direction: ltr;
}
.graph path {
  visibility: visible;
}
.graph svg > g > g:nth-child(3) > g text {
  text-transform: uppercase;
}
.labels {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #c3bbb5;
  display: flex;
}
.label {
  font-size: 13px;
  font-weight: bold;
  color: #796e6b;
  text-transform: uppercase;
  position: relative;
  margin-right: 10px;
  padding-left: 35px;
}
.label:before {
  content: " ";
  height: 9px;
  background-color: #9fd4ba;
  border-radius: 10px;
  width: 30px;
  display: block;
  left: 0;
  position: absolute;
  top: 5px;
}
.label.avg:before {
  height: 3px;
  background-color: #a5b5fc;
  top: 8px;
}
.HapinessTrackeremptyState {
  width: 168px;
  height: 169px;
  opacity: 0.78;
  margin: 40px auto;
}
