
.container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 100px;
}

.share{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.text{
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 2px;
  height: 17px;
  font-family: var(--font-family-primary);
}

.img{
  margin-left: 5px;
  width: 22px;
  height: 21px;
}
