.container{
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  border-top: solid 1px #c3bbb559;
  align-items: center;
  justify-content: space-between;
}

.details {
  font-size: 22px;
  font-weight: normal;
  line-height: 1;
  color: #3e3330;
}

.upgrade {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  color: #eaaa82;
  cursor: pointer;
  user-select: none;
}
