.root {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2rem;
}

.root.flexColumn {
  flex-direction: column;
}

.timeDiv {
  font-size: 30px;
  line-height: 0.73;
}

.timeBtn {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  color: #eaaa82;
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;
}
