
.title {
  font-size: 22px;
  font-weight: normal;
  line-height: 1;
  color: #3e3330;
}

.text {
  font-family: var(--font-family-secondary);
  font-size: 20px;;
  color: #3e3330;
}

.separator {
  margin-top: 15px;
  margin-bottom: 15px;
  border-top: solid 1px #c3bbb559;
}
