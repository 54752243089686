
.swiper-pagination {
  bottom: -5px !important;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: #3C3330;
  margin-top: 20px;
}

.swiper-pagination-bullet-active {
  width: 28px;
  height: 6px;
  border-radius: 20px;
  background: #3C3330 !important;
}
