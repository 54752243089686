.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.slideContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  padding-top: 40px;
  padding-bottom: 100px;
}

.title{
  color: #3C3330;
  text-align: center;
  font-family: var(--font-family-secondary);
  font-size: 37px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
}

.text{
  padding-top: 20px;
  color: #6D6C6C;
  text-align: center;
  font-family: var(--font-family-primary);
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}
