.button {
  border-width: 0;
  border-radius: 18px;
  box-shadow: 0 5px 7px -3px rgba(149, 137, 115, 0.5);
  padding: 3px 5px 3px 5px;
  height: 40px;
}

.label {
  display: flex;
  align-items: center;
  font-size: 15px;
  text-align: center;
  width: 100%;
  height: 30px;
}
