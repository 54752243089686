.list {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;

  .title {
    font-size: 18px;
    font-weight: normal;
    line-height: 1;
    color: #3e3330;
  }
}
