body {
  font-family: "Brandon", ״-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  --font-family-primary: Brandon, 'Helvetica Neue', sans-serif;
  --font-family-secondary: Recoleta, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #FBEEDD;
}

@font-face {
  font-family: "Recoleta";
  src: local("Recoleta"), url(./assets/Recoleta.woff2) format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "Recoleta-Bold";
  src: local("Recoleta-bold"), url(./assets/Recoleta-bold.woff2) format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "Recoleta";
  src: local("Recoleta-light"),
  url(./assets/Recoleta-light.woff2) format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "Recoleta";
  src: local("Recoleta-bold"), url(./assets/Recoleta-bold.woff2) format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "Brandon";
  src: local("Brandon_bld"), url(./assets/Brandon_bld.otf) format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "Brandon";
  src: local("Brandon_reg"), url(./assets/Brandon_reg.otf) format("woff");
  font-weight: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
