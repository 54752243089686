.container {
    display: flex;
    width: calc(100% - 20px);
    max-width: 500px;
    flex-direction: column;
    margin-bottom: 20px;
}

.welcomeMessage {
  height: 25px;
  font-family: var(--font-family-secondary);
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.13px;
  color: #ff9e4f;
}

.entered{
    margin-top: 20px;
    font-family: var(--font-family-secondary);
    font-size: 37px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: -0.19px;
    color: #3e3330;
}

.joined{
    margin-top: 20px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: normal;
    color: #3e3330;
}
