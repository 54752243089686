.locale_default {
  // Edit index.css in the root to revise default styling
}

.locale_en_US {
}

.locale_he_IL {
  font-family: 'Miriam Libre', 'Helvetica Neue', sans-serif;
  --font-family-primary: 'Miriam Libre', 'Helvetica Neue', sans-serif;
  --font-family-secondary: 'Miriam Libre', 'Helvetica Neue', sans-serif;
  direction: rtl;
}
