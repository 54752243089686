.container {
    display: flex;
    width: calc(100% - 40px);
    max-width: 500px;
    flex-direction: column;
    padding-top: 20px;
    margin-right: auto;
    margin-left: auto;
}

.logoContainer{
    display: flex;
    flex-direction: row;
    justify-content: left;
}
.header{
    display: flex;
    flex-direction: row;
    padding-bottom: 13px;
    border-bottom: 1px solid #c3bbb5;
    justify-content: space-between;
    align-items: baseline;
}
.calendar{
    width: 17px;
    height: 16px;
    object-fit: contain;
}
.date{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.dateText{
}

.welcomeMessage{
    display: flex;
    flex-direction: row;
    align-self: center;
    font-weight: 700;
    font-size: 20px;
    justify-content: center;
}
