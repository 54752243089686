
.container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-image: url('../../assets/background.jpeg');
  background-size: cover;
  background-position: center;
}

.bottomBg {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50vh;
  background-image: url('./assets/bottomBg.svg');
  background-repeat: no-repeat;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
}

.logoContainer {
  padding-top: 80px;
}

.logo{
  width: 45px;
}

.slidesContainer{
  width: 100vw;
  height: 340px;
  padding-top: 60px;
  padding-bottom: 100px;
}

.buttonContainer{
  padding-bottom: 60px;
}

.swiper{
  height: 450px;
}

@media (max-height: 800px) {
  .swiper {
    height: 410px;
  }
  .slidesContainer{
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
  }
}
