.header {
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: -0.21px;
  color: #ff9e4f;
  padding-bottom: 5px;
  font-family: var(--font-family-secondary);
  width: 100%;
  max-width: 500px;
  margin: auto;
  margin-bottom: 1rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  //padding: 10px 40px;
  width: calc(100% - 20px)
}
