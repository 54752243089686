.button {
  border-radius: 24px;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition-duration: 0.4s;
  width: 344px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon{
  width: 27px;
  height: 27px;
  padding-right: 10px;
}

.main {
  background-color: #3C3330;
}

.secondary {
  border: 1px solid #3C3330;
  background-color: transparent;
  color: #3C3330;
}

.link {
  background: none;
  color: #3C3330;
  border: none;
  height: auto;
  width: auto;
}

.danger {
  background-color: #ff3700;
}

.buttonMain:hover, .buttonSecondary:hover {
  opacity: 0.8;
}
