
.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  margin-top: 200px;
  font-size: 20px;
  font-family: var(--font-family-primary);
  color: #544b46;
}

.container div{
  text-align: left;
  width: 90%;
}

.container div a{
  color: #544b46;
}
