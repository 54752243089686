.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dot {
  width: 9px;
  height: 9px;
  border-radius: 9px;
  margin-right: 9px;
}
