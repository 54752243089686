
.tagLine {
  text-align: center;
  font-family: var(--font-family-secondary);
  font-size: 40px;
}

.mainContainer {
  padding: 0px 90px 0px 90px;
  border-radius: 10px;
  background-color: #fdf9f3;
}

.scoreContainer {
  font-family: var(--font-family-secondary);
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-around;
  font-size: 130px;
  line-height: 180px;
  direction: ltr;
}

.footerContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 80px;
}

.footerTextContainer{
  font-family: var(--font-family-primary);
  font-size: 22px;
  font-weight: bold;
}

.footerText {
  max-width: 220px;
}

.badgeContainer {

}
.badgeContainer img{
  position: relative;
  top: -30px;
  left: -8px;
  text-align: right;
}
