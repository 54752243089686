
.container {
  flex-direction: column;
  display: flex;
  height: 128px;
  background-color: #fefdfc;
  padding: 11px;
  margin: 10px;
  border-radius: 20px;
  box-shadow: 0 2px 10px 4px rgba(218, 218, 218, 0.5);
  font-family: var(--font-family-primary);
}

.contentContainer {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.infoContainer{
  width: 60%;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 23px;
  text-align: left;
  font-weight: 500;
  width: 100%;
  margin-bottom: 10px;
}

.module {
  max-width: 150px;
  min-width: 89px;
  height: 29px;
  padding: 5px 8px;
  border-radius: 5px;
  border: solid 1px;
  font-size: 18px;
  letter-spacing: normal;
  text-align: center;
  display: inline-block;
}

.thumbnailContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.thumbnailContainer img{
  width: 173px;
  height: 106px;
}

.practiceLength{
  position: absolute;
  top: 4px;
  left: 8px;
  font-size: 18px;
  color: #3e3330;
  opacity: 0.5;
}
