.container {
    display: flex;
    width: calc(100% - 20px);
    max-width: 500px;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 5px;
    margin-right: auto;
    margin-left: auto;
}

.welcomeMessage {
  font-family: var(--font-family-secondary);
  font-size: 45px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.23px;
  color: #ff9e4f;
}

.today{
  font-family: var(--font-family-primary);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: rgba(62, 51, 48, 0.57);
  margin-bottom: 13px;
}
