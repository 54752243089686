.container {
    display: flex;
    width: calc(100% - 40px);
    max-width: 500px;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 5px;
    margin-right: auto;
    margin-left: auto;
}

.entrered{
    font-family: var(--font-family-secondary);
    font-size: 37px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: -0.19px;
    color: #3e3330;
    margin-bottom: 5px;
}

.joined{
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: rgba(62, 51, 48, 0.57)
}
