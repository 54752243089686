
.text {
  text-align: center;
  font-family: var(--font-family-secondary);
  font-size: 22px;
  padding-bottom: 10px;
}

.buttonContainer{
  display: flex;
  height: 90px;
  justify-content: center;
  align-items: center;
}

.buttonContainer a {
  text-decoration: none;
}

.deepLinkButton {
  background-color: #fbeedd;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 335px;
  height: 75px;
  font-family: var(--font-family-primary);
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.78;
  letter-spacing: normal;
  text-align: center;
  color: #fdf9f3;
  border-radius: 50px;
  box-shadow: 0 8px 10px -3px rgba(149, 137, 115, 0.5);
}

.deepLinkButton.discord{
  background-color: #5656eb;
}

.deepLinkButton.discord .buttonText{
  color: white;
}

.platformIcon{
  margin-right: 15px;
}

.platformIcon img{
  width: 50px;
}
.buttonText{
  padding-right : 15px;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02px;
  text-align: center;
  color: #3e3330;
  font-family: var(--font-family-primary);
}
