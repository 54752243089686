
.practice{
  background-color: #fdf9f3;
  font-family: var(--font-family-primary);
  color: #544b46;
  height: 100%;
  min-height: 100vh;
}

.container{
  padding: 20px;
}

.playerContainer{

}

.title{
  font-family: var(--font-family-secondary);
  font-size: 31px;
  text-align: left;
  font-weight: bold;
  width: 100%;
  margin-bottom: 10px;
}
