
.container{
  height: 100vh;
  width: 100vw;
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.25px;
  color: #3e3330;
}

.storeButtonsContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.appStoreButton {
  width: 13rem;
}

.playStoreButton {
  width: 15rem;
}

.title{
  font-family: var(--font-family-secondary);
  margin-bottom: 1rem;
  font-size: 50px;
}

.info{
  margin: 0.5rem 1.25rem;
  font-family: var(--font-family-primary);
  font-size: 25px;
}

.activateButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 17rem;
  height: 4.6rem;
  background-color: #fefdfc;
  font-family: var(--font-family-primary);
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.78;
  letter-spacing: normal;
  text-align: center;
  color: #3e3330;
  border-radius: 50px;
  box-shadow: 0 8px 10px -3px rgba(149, 137, 115, 0.5);
  text-decoration: none;
  margin-top:1.5rem;
  min-height: 4.6rem;
}

.issues{
  margin-top: 1.25rem;
  color: #3e3330;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  width: calc(100% - 50px);
  text-align: left;
}

.issues a{
  color: #3e3330;
}
