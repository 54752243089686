.splashContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-image: url('./assets/SplashScreen.jpeg');
  background-size: cover;
  background-position: center;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 140px;
  flex-direction: column;
  padding-bottom: 100px;
}

.logo {
  padding-bottom: 30px;
}

.splashText {
  color: #FFF;
  font-family: var(--font-family-primary);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  padding-top: 20px;
}
