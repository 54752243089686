.tooltip {
  background: #000000c0;
  padding: 9px 12px;
  border-radius: 5px;
}

.tooltip .score {
  padding: 3px 0;
}

.tooltip .time {
  color: white;
}