.container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-image: url('../../assets/background.jpeg');
  background-size: cover;
  background-position: center;
}

.bottomBg {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50vh;
  background-image: url('assets/bottomBg.svg');
  background-repeat: no-repeat;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
}

.logo{
  width: 86px;
  padding-top: 180px;
}

.bottom{
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
  height: 270px;
}

@media (max-height: 800px) {
  .bottom {
    height: 210px;
  }
}

.buttonsContainer{
  display: flex;
  flex-direction: column;
  padding-top: 40px;
}

.title{
  color: #3C3330;
  text-align: center;
  font-family: var(--font-family-secondary);
  font-size: 37px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
}

.text{
  padding-top: 20px;
  color: #6D6C6C;
  text-align: center;
  font-family: var(--font-family-primary);
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}
