.root {
  width: 50%;
  min-height: 91px;
  font-family: var(--font-family-primary);
  color: #3e3330;
  flex-grow: 0;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.titleWrapper {
  font-size: 22px;
  line-height: 1;
  letter-spacing: normal;
  display: flex;
  justify-content: space-between;
}
.titleWrapper.disabled {
  opacity: 0.4;
}

.titleWrapper .title {
  margin-right: 4px;
}

.offNote {
  opacity: 0.4;
  font-size: 17px;
  line-height: 1.4;
  margin: 0 9px;
}
