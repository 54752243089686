.newUserPageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  gap: 1rem;
}

.container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-image: url('../../Onboarding/assets/background.jpeg');
  background-size: cover;
  background-position: center;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100%
}

.logo{
  width: 86px;
  padding-top: 180px;
}

.bottom{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-image: url('assets/bottomBg.svg');
  height: 50vh;
  width: 100%;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex:1;
  padding-bottom: 1rem;
}

@media (max-height: 800px) {
  .bottom {
    height: 70vh;
  }
  .logo{
    padding-top: 90px;
  }
}

.title{
  color: #3C3330;
  text-align: center;
  margin-top: 4rem;
  font-family: var(--font-family-secondary);
  font-size: 32px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
}

.text{
  padding: 20px 2rem 0;
  color: #6D6C6C;
  text-align: center;
  font-family: var(--font-family-primary);
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.boldText{
  padding: 20px 2rem 0;
  color: #6D6C6C;
  text-align: center;
  font-family: var(--font-family-primary);
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

