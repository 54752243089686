.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.inputWrapper {
  direction: ltr;
}

.inputWrapper [type="time"]{
  padding: 0 4px !important;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

.inputWrapper [type="time"]::-webkit-date-and-time-value{
  text-align: center;
}

.inputWrapper [type="time"]::-webkit-date-and-time-value{
}

.timeBtn {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  color: #eaaa82;
  cursor: pointer;
  user-select: none;
}

.inputWrapper [type="time"],
.inputWrapper [type="text"] {
  background: #fbeedd;
  border: solid 1px #eaaa82;
  font-size: 30px;
  line-height: 0.8;
  font-family: var(--font-family-primary);
  padding: 0 !important;
  margin-bottom: -4px;
  max-height: 33px;
  height: 33px;
  box-sizing: border-box;
}

.inputWrapper [type="text"] {
  width: 80px;
  font-family: system-ui;
  font-weight: 300;
}

.inputWrapper [type="time"]:focus,
.inputWrapper [type="text"]:focus {
  outline: none !important;
}

.inputWrapper [type="time"]::-webkit-datetime-edit-hour-field,
.inputWrapper [type="time"]::-webkit-datetime-edit-minute-field,
.inputWrapper [type="time"]::-webkit-datetime-edit-ampm-field {
  color: #3e3330;
}

.inputWrapper [type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}
.inputWrapper [type="time"]::-webkit-datetime-edit-text {
  color: #eaaa82;
}

.inputWrapper [type="time"]::-webkit-date-and-time-value {
  color: #3e3330;
  line-height: 0.8;
  margin: 0;
}

.inputWrapper .err {
  font-style: italic;
  color: #3e3330;
  font-size: 14px;
  margin-bottom: -20px;
  line-height: 1.4;
}
