.Homepage {
  background-color: #fdf9f3;
  font-family: var(--font-family-primary);
  color: #544b46;
  height: 100%;
  min-height: 100vh;
}

.loadingContainer {
  display: flex;
  min-height: 70vh;
  height: 70%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
}

.container {
  display: flex;
  width: calc(100% - 80px);
  max-width: 500px;
  flex-direction: column;
  background-color: #fefdfc;
  padding: 20px;
  margin: 20px auto;
  border-radius: 20px;
  box-shadow: 0 2px 10px 4px rgba(218, 218, 218, 0.5);
}

.container .header {
  font-family: var(--font-family-secondary);
  font-size: 27px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: #3e3330;
  padding-bottom: 20px;
  border-bottom: 1px solid #c3bbb5;
}
.emptyStateLabel {
  font-family: var(--font-family-secondary);
  font-size: 20px;
  line-height: 1.4;
  text-align: center;
  color: #3e3330;
  margin: 0 5%;
}
.loading_wrapper {
  width: 120px;
  margin: 60px auto;
}

.divider{
  background-color:#c3bbb5;
  width: calc(100% - 40px);
  max-width: 500px;
  height: 1px;
  margin-left: 20px;
  margin-right: 20px;
  margin: auto;
}

.spacer{
  margin-bottom: 20px;
}

.homepageCardsContainer{
  margin: 20px 5px 5px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#loading {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 15px 15px 0 0 #3e3330;
  animation: moon_spin 3s linear infinite;
}

@keyframes moon_spin {
  0% {
    transform: rotate(0deg);
    box-shadow: 15px 15px 0 0 #666;
    background: #fffde7;
  }
  66% {
    box-shadow: 15px 15px 0 0 #3e3330;
    background: #fbc02d;
  }
  100% {
    transform: rotate(720deg);
    box-shadow: 15px 15px 0 0 #666;
  }
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  width: 80% !important;
  margin: 0 10% !important;
}
.swiper-pagination-bullet {
  background: #e8dcc8 !important;
  opacity: 1 !important;
}
.swiper-pagination-bullet-active {
  background: #bd82dd !important;
}
[class^="swiper-button-"] {
  bottom: 0 !important;
  top: initial !important;
}

[class^="swiper-button-"]:after {
  font-size: 16px !important;
  color: #3e3330;
}
