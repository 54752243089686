.addEntry {
  font-family: var(--font-family-secondary);
  font-size: 25px;
  font-weight: bold;
  border-radius: 32.5px;
  border: solid 1px #ff9e4f;
  background-color: #fbeedd;
  cursor: pointer;
  padding: 17px;
  width: calc(100% - 20px);
  height: 65px;
  max-width: 540px;
  display: block;
  margin-bottom: 20px;
  color: #3e3330;
}
