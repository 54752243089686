.container {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 500px;
  background-color: #fefdfc;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
  -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.1607843137254902));
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.1607843137254902));
}

.dateHeader {
  padding-bottom: 12px;
  font-family: var(--font-family-secondary);
  font-size: 13.9px;
  font-weight: 600;
  border-bottom: 1px solid #e7e1d8;
  margin-bottom: 23px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.date {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.calendar {
  margin-bottom: 3px;
}

.editButton {
  text-transform: uppercase;
  font-family: var(--font-family-primary);
  line-height: 1.15;
  color: #948e8a;
  border: none;
  background: none;
  justify-self: right;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.25rem;

  svg {
    margin-right: 5px;
  }
}

.activeButton {
  color: #ff744f;
}
