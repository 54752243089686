
.container{
  margin: 15px 15px 0 15px;;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contentContainer{
  display: flex;
  flex-direction: column;
  text-align: left;
}

.text{
  font-family: var(--font-family-secondary);
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: -0.13px;
  color: #fff;
}

.author{
  margin-top: 5px;
  font-family: var(--font-family-secondary);
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1.32;
  letter-spacing: -0.13px;
  color: #fff;
  font-style: italic;
}

.content{
  margin-bottom: 10px
}

.footer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pagination{
  display: flex;
  width: 100%;
}

.actions{
  margin-bottom: 5px;
}
