:root {
  --modal-top-margin: 30px;
}

ion-content {
  --background : #fdf9f3;
}

ion-tab-bar {
  height: 65px;
}

ion-tab-button {
  --color : #3e3330;
  --color-selected : #FF744F;
  --padding-bottom : 12px;
}
