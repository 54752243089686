.entries {
  display: flex;
  gap: 15px;
  margin-bottom: 16px;
}

.headerIcon {
  height: 20px;
  margin-top: 6px;
}

.entry {
  width: 100%;
}

.readonlyContainer {
  font-family: var(--font-family-primary);
  font-size: 22px;
}

.editableContainer {
  background: #fbeedd;
  display: grid;
  grid-template-columns: 1fr 35px;
  margin-top: 5px;

  textarea {
    font-family: var(--font-family-primary);
    font-size: 22px;
    border: none;
    background: none;
    resize: none;
    line-height: 1.3;
    outline: none;
  }
}

.binButton {
  background: none;
  border: none;
  justify-self: right;
  margin-top: 5px;
  margin-right: 5px;
  width: 25px;
  height: 25px;
}

.title {
  font-family: var(--font-family-primary);
  font-size: 15px;
  color: rgba(62, 51, 48, 0.57);
  line-height: 1.27;

  &:first-letter {
    text-transform: capitalize;
  }
}

.moreLink {
  font-family: var(--font-family-primary);
  font-size: 18px;
  line-height: 1.06;
  color: #ff744f;
  cursor: pointer;
  display: block;
  margin-top: 8px;
}
