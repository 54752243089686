.tos{
  margin-top: 10px;
  font-family: var(--font-family-primary);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  text-align: center;
  color: #3e3330;
}

.tos a{
  color: #3e3330;
}
