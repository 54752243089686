.overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(62, 51, 48, 0.73);
}

.panel {
  margin: 160px auto 0;
  background: #fefdfc;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 6;
  overflow-y: auto;
  max-width: 306px;
  height: auto;
  border-radius: 13px;
  padding: 20px;
  box-shadow: 0 2px 10px 4px rgba(76, 76, 76, 0.5);

  p {
    font-family: var(--font-family-secondary);
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 10px;
  }
}

.buttons {
  text-align: right;
}

.button {
  font-family: var(--font-family-primary);
  font-size: 21px;
  font-weight: bold;
  line-height: 0.95;
  letter-spacing: -0.1px;
  color: #ff744f;
  background: none;
  padding: 7px 10px;
  margin-left: 5px;
  border: none;
}
