.root {
  margin-top: 18px;
  margin-bottom: 10px;
  border-top: solid 1px #c3bbb559;
  &.invisible {
    display: none;
  }
  .list {
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 22px;
      font-weight: normal;
      line-height: 1;
      color: #3e3330;
    }
  }
}
